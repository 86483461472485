.App{
}

@font-face {
  font-family: "HappyEnding-Regular";
  src: url("static/fonts/HappyEnding-Regular.woff") format("woff");
}
@font-face {
  font-family: "Somma-Light";
  src: url("static/fonts/Somma-Light.otf") format("opentype");
}
@font-face {
  font-family: "Somma-Medium";
  src: url("static/fonts/Somma-Medium.otf") format("opentype");
}

@media only screen and (min-width: 20px) {
  /* For phone: */
  .breadcrumbs, .header_button{ font-size: 10px; }
  .header_main{ font-size: 50px; }
  .header_text2, .phone_hide, .phone_hide_il, .phone_hide_tc { display:none; }
  .phone_unhide { display:block; }
  .header_img { height: 50px; }
  .search_panel_facet { font-size: 10px; }
  .app-header {  }
  .header_search{  }
  .wave_header { top: 13px; }
  .wave { background-size: 140% 100%; }
  .wave_img { max-height: 150px; min-width: 70px; }
  .wave_gap{ height: 100px; }
  .wave_block { font-size: 10px; }
  .wave_text { top:30px; }
  .wave_text_main{ font-size: min(max(15px, 5vw), 30px); }
  .wave_text_sub1, .wave_text_sub2{ font-size: 10px; display: none; }
  .wave_container_gap { height: 110px; }
  .wave_container_gap_header { display: block; height: 110px; }
  .wave_container_gap_purchase_hist { height: 150px; }
  .wave_container { height: 100% }

  .download_img_ovw { width: 100%; }
  .download_img { width: 100%; max-height: 44vh; }
  .download_img_plus { height: 10px; }
  .download_photo_img { max-width: 100%; }
  .download_photo_container, .download_photo_container3{ height: 300px; }
  .download_photo_container4{ width: 100%; }
  .download_photo_container_int4{ max-height: 100%; }
  .download_block_blk{ display: none; }
  .download_title{ font-size: 15px; }
  .download_text, .download_credit{ font-size: 10px; }
  .download_footer, .download_footer_warning, .download_footer_credit { font-size: 10px; }
  .download_button{ font-size: 10px; }
  .remove_button{ font-size: 10px; }
  .download_nologin{ font-size: 10px; }
  .carousel_img img{ max-width: 50vw; }

  .card_photo_container3{ height: 120px; }
  .card-img{ height: 120px; width: inherit; }

  .card-columns { column-count: 1 !important; }
  .card-body {padding: 0;}
  .card_text { font-size: 10px; background-color: rgba(255,255,255,0.4);}
  .card_text_zone{ width: 50%; }
  .LinkContainer{ font-size: 14px; }
  .FooterLinkContainer{ font-size: 8px; }
  .style_red_bold, .style_text_warning{ font-size: 15px; }
  .style_text_bigger, .style_text_bigger_thin, .style_text_bigger_bold, .style_text_bigger_bold_warning{ font-size: 12px; }

  .search_facet{font-size: 10px;}
  .search_option_label{font-size: 8px;}

  .information_text_header{ font-size: 35px; top: 0px; }
  .information_title{ font-size: 15px; }
  .information_text, .text{ font-size: 10px; }
  .information_important{ font-size: 10px; }
  .selectForm{ padding: 5px 0 5px 0; }
  .user_account_label, .user_account_input{ font-size: 10px; min-width: 100px; }
  .user_account_item_email{ width: auto; min-width: 70px; }
  .user_account_country { max-width: 115px; }
  .login_container{ position: relative; text-align: center; }
  .socialLink{ position: relative; text-align: center; }

  .login{ position: relative; display: block; text-align: center; }
  .logout{ position: relative; display: block; text-align: center; }

  .logo{ width: 70px; }
  #shoppingCart{ height:16px; }
  #shoppingCartNbItems{ right: -2px; top: -22px; font-size: 9px; text-align: center; width: unset;}
  .tooltip{ display: block; }

  .inf_LinkContainer{ margin: 10px; padding: .6rem 2.0rem; font-size: calc(8px + 1vmin);}
  .information_text_container_purchase_hist{ padding: 20px 10px 20px 0 !important; }
  .wave_footer_menu{  }

  .socialLink{  }
  .socialIcon{ margin: 2px 5px 0 5px; width: 20px; }

  #example_popover{ max-width: 360px;}
  .example_img { max-height: 200px; }
  .examples_header{ top: 15px; }
}

@media only screen and (min-width: 990px) {
  .breadcrumbs, .header_button{ font-size: 12px; }
  .header_main{ font-size: 50px; }
  .header_text { display:block; font-size: 20px; }
  .header_text2 { display:block; font-size: 15px; }
  .phone_hide { display:block; }
  .phone_hide_il { display:inline; }
  .phone_hide_tc { display:table-cell; }
  .phone_unhide { display:none; }
  .header_img { height: 50px; }

  .wave { background-size: 100% 100%; }
  .wave_header { height: 20px; top: -40px; }
  .wave_img { width: auto; max-height: 200px; }
  .wave_gap{ height: 100px; }
  .wave_text_main{ font-size: min(max(15px, 5vw), 35px); }
  .wave_text_sub1, .wave_text_sub2{ display: block; }
  .wave_container_gap { height: 110px; }
  .wave_container_gap_header { height: 90px; }
/*
  .wave_container_gap_footer { display: block; height: 350px; }
  .wave_container { height: 900px; }
  .wave_footer_menu{ padding: 100px 0 0 0; }
*/
  .download_photo_container4{ width: 80%; }
  .download_photo_container_int4{ max-height: 100%; }
  .download_block_blk{ display: block; }
  .download_img_plus { height: 20px; }
  .download_title{ font-size: 20px; }
  .download_button{ font-size: 15px; }
  .remove_button{ font-size: 10px; }
  .download_nologin{ font-size: 20px; }

  .download_photo_container, .download_photo_container3{ height: 350px; }

  .carousel_img img{ max-width: 20vw; }

  .card_photo_container3{ height: 220px; }
  .card_img{ height: 150px; }

  .card-columns { column-count: 2 !important; }
  .card-body {padding: 1.25rem;}
  .card_text_zone{ width: 80%; }

  .selectForm{ padding: 5px 0 5px 0; }
  .tooltip{ display: none; }
  .information_text_container_purchase_hist{ padding: 20px 20px 20px 20px !important; }

  #shoppingCartNbItems{ right: 27px; top: 8px; font-size: 9px; width: 0; }

  #example_popover{ max-width: 1300px;}
  .example_img { max-height: 500px; }
  .examples_header{ top: 0px; }
}

@media only screen and (min-width: 1200px) {
  .wave_img { width: auto; max-height: 250px; }

  .socialIcon{ margin: 5px 10px 0 10px; width: unset; }
}

@media only screen and (min-width: 1550px) {
  /* For tablets and bigger: */
  .breadcrumbs, .header_button{ font-size: 18px; }
  .header_main{ font-size: 70px; }
  .header_text {font-size: 30px;}
  .header_text2 {font-size: 15px;}
  .search_panel_facet { font-size: 20px; }
  .header_img { height: 100px; }
  .app-header { height: 180px; }

  .wave_header { height: 20px; top: -110px; }
  .wave_img { width: auto; max-height: unset; }
  .wave_block { font-size: 20px; }
  .wave_text { top:80px; }
  .wave_text_main{ font-size: 50px; }
  .wave_text_sub1, .wave_text_sub2{ font-size: 19px; }
  .download_img_ovw { max-height: 340px; width: unset; }
  .download_img { max-height: unset; height: unset; }

  .download_photo_container, .download_photo_container3{ height: 400px; }

  .download_title{ font-size: 35px; }
  .download_button{ font-size: 20px; }
  .remove_button{ font-size: 20px; }
  .download_text, .download_credit{ font-size: 16px; }
  .download_footer, .download_footer_warning, .download_footer_credit { font-size: 20px; }
  .button_space{ height: 10px;}
  .card_photo_container3{ height: 350px; }
  .card_photo_container_int3 {  }
  .card_img{ height: auto; }
  .card-columns { column-count: 4 !important; }

  .card_text { font-size: 20px; background: none}
  .card_text_zone{ width: auto; }
  .LinkContainer{ font-size: 20px; }
  .FooterLinkContainer{ font-size: 20px; }
  .style_red_bold, .style_text_warning{ font-size: 21px; }
  .style_text_bigger, .style_text_bigger_thin, .style_text_bigger_bold, .style_text_bigger_bold_warning{ font-size: 19px; }

  .search_facet{font-size: 16px;}
  .search_option_label{font-size: 12px;}

  .information_text_header{ font-size: 60px; top: -20px; }
  .information_title{ font-size: 24px; }
  .information_text, .text{ font-size: 20px; }
  .information_important{ font-size: 20px; }
  .selectForm{ padding: 5px; }

  .user_account_label, .user_account_input{ font-size: 20px;  min-width: 135px; }
  .user_account_item_email{ width: calc(70px + 30vh); min-width: 70px; }
  .user_account_country { max-width: 250px; }

  .login{ display: block; }
  .logout{ display: block; width: unset;}

  .logo{ width: 100%; }
  #shoppingCart{ height:26px; }
  #shoppingCartNbItems{ right: 33px; top: 12px; font-size: 10px; }
}


.screen_background, .screen_background_white{
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 0;
  top: 0;
}
.screen_background{
  z-index: -2;
  height: 100%;
}

.screen_background_white{
  background-color: white;
  z-index: -1;
  height: 500px;
}

.header_nav_button{
  margin: auto;
}

.wave_text_main, .header_main {
  font-family: "HappyEnding-Regular", "Somma-Light","Trebuchet MS", Times, serif;
}
.app-header, .user_account_error, .download_error, .breadcrumbs
  , .search_facet_change_on, .search_facet_change_off
  , .style_red_bold, .style_text_warning, .wave_text_sub1, .style_text_bold,
  .information_important {
  font-family: "Somma-Medium","Trebuchet MS", Times, serif;
}
.wave_text_sub2 {
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
}

.login{
  color: black;
  background-color: rgb(229, 211, 234);
  border: 2px solid rgb(110, 50, 120);
}
.login_name {
  color: rgb(120, 63, 129);;
  font-weight: bold;
}
.login_name {
  margin: 0px 10px 0px 10px;
}
.login_LinkContainer{
  width: 100%;
}
/*
.login_LinkContainer {
  border-radius: 0px;
  color: white;
  background-color: rgb(110, 50, 120);
  border: 2px solid rgb(110, 50, 120);
}
*/
.app-logo {
  position: relative;
  float: left;
  top: 10px;
  z-index: 15;
}
.navbar{
  padding: 0 0 0 0px;
  z-index: 9;
}
.debug{
  color: black;
  font-size: 10px;
}
.app-header {
  color: white;
}

.breadcrumbs{
  color: black;
  padding: .275rem 0 0 .75rem;
  margin: 15px 0 0 0;
  text-shadow: 4px 4px 4px #aaa;
}
.link_visible, .breadcrumb-active{
  text-decoration: underline;
}

.LinkContainer{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  font-weight: 200;
  color: rgb(120, 63, 129);
}
.btn-light{
  background-color: white;
  border-color: white;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: rgb(120, 63, 129);
}
.btn{
  padding: .275rem .75rem;
}

.activity{
	z-index: 1;
	position:relative;
}

.page_link{
  cursor: url(logo32.ico),grab;
}

.header_text, .header_text2, .header_main{
  color: rgb(120, 63, 129);
}
.header_main{
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
.header_text, .header_text2{
	position:relative;
	font-family: "Somma-Light","Trebuchet MS", Times, serif;
  font-weight: 300;
}


a:link {
  color: rgb(120, 63, 129);
}

/* visited link */
a:visited {
  color: rgb(120, 63, 129);
}

/* mouse over link */
a:hover {
}

.wave_header {
  text-align: center;
  z-index: 1;
  position: relative;
  width: 100%;
}
.wave_block {
  text-align: center;
  color: rgb(120, 63, 129);
  display:block;
  z-index: 1;
}

.wave_link{
  position: relative;
  height:100%;
}

.wave_text_main{
  position: relative;
  font-weight: bold;
  margin:0;
}
.wave_text_sub1{
  font-weight: 600;
}
.wave_text_sub2{
  font-weight: 200;
}

.wave_text_sub1, .wave_text_sub2{
  position: relative;
}
/*
  border: 1px solid rgb(110, 50, 120);
  background-color: rgb(120, 63, 129);
*/

.wave_img{
}
.wave_text {
  position: relative;
  max-width: 53%;
  display: inline-block;
}
.wave_text p{
  margin:0;
}

.wave_background{
  position: relative;
  min-height: calc(min(600px, 90vh));
}
/*
*/

/*
background-color: white;
*/
.wave_container, .wave_container_noextra {
  position: absolute;
  background-repeat: repeat-x;
  width: 100%;
  padding: 0;
  margin: 0;
}
.wave_container_gap, .wave_container_gap_header, .wave_container_gap_footer, .wave_container_gap_purchase_hist {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
}
/*
max-height: 350px;
*/
.wave_container_subject {
  position:relative;
  width: 100%;
  padding: 0 0 30px 0;
}
/*
.wave_content{
  min-height: calc(100vh - 150px - 64px);
}
.wave_content_ph{
  min-height: calc(100vh - 150px - 150px - 66px);
}

*/

.wave_content{
  width: 100%;
  padding : 0 0 0px 0;
  margin: 0 0 0px 0;
}
/*
margin: 100px 0 0 0;
*/
.wave_footer_menu{
  position: fixed;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  bottom: 0px;
  z-index: 10;
}
.wave_footer_menu div a {
  padding: 0 0 0 15px;
}

.wave_end{
  position: relative;
  z-index:2;
}
.download_wave_end{
  position:relative;
  height: calc(100vh - 1050px - 0px);
}

.wave_footer {
	width: 100%;
  position:relative;
	z-index: 0;
  border: 0;
  margin: 0;
  top: 100px;
  display: flex;
  justify-content: flex-end;
}

/*
border: 0;
*/
.wave_gap, .wave {
	width: 100%;
	position:relative;
	z-index: auto;
  border: 1px solid rgb(110, 50, 120);
  margin: 0;
}

.wave {
	position:absolute;
	background-repeat: repeat-x;
}
.wave_set {
	position:relative;
	width: 100%;
	z-index: auto;
  border: 0;
  margin: 0;
}

.img_photo{
	max-height: 270px;
}

/* motion */
.pdfcarousel {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  height: 700px;
  position: relative;
}
.pdfcarousel-inner {
  overflow: hidden;
  position: relative;
  margin: auto;
}
.pdfcarousel-photo {
  position: absolute;
  background-color: lightgray;
}

.axis-vertical {
}

.download_button_more { display: inline; }


.download_block {
  position: relative;
  margin: 10px 0 10px 0;
  z-index: 5;
}
.download_photo_container, .download_photo_container3, .card_photo_container3{
  position: relative;
}
.download_photo_container_int, .download_photo_container_int3, .card_photo_container_int3{
  position: absolute;
  bottom: 0px;
}
.download_img_ovw {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.download_img {
  position: relative;
}
.download_popup{
  border: 2px solid rgb(110, 50, 120);
  background-color: rgb(228, 211, 233);
}
.paymentBank{
  top: 200px;
}
.carousel_block{
  margin: 0 auto;
  z-index: 5;
}
.carousel_img{
  margin: 15px;
}

.carousel .slide{
  background:0 !important;
}

.download_title, .download_title_img{
	margin: 10px 0 0 0;
}
.download_title_img{
  height: 40px;
}
.download_title{
  font-family: "HappyEnding-Regular", "Somma-Light","Trebuchet MS", Times, serif;
  color: rgb(110, 50, 120);
  text-align:left;
}

.download_text, .download_credit{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  font-weight: 200;
  color: rgb(110, 50, 120);
  text-align:left;
}
.download_text_container{
  position: relative;
  min-height: 100px;
}
.download_button, .remove_button{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;

  border-color: rgb(120, 63, 129);
  background-color: rgb(110, 50, 120);
  color: white;
	margin: 10px 10px 0px 10px;
	position: relative;
  white-space: nowrap;
}
.download_nologin{
  font-family: "HappyEnding-Regular", "Somma-Light","Trebuchet MS", Times, serif;
  color: red;
  width: auto;
	margin: 10px 10px 0px 10px;
	position: relative;
}
.download_footer, .download_footer_warning, .download_footer_credit {
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  border-color: rgb(120, 63, 129);
  color: rgb(120, 63, 129);
  display: none;
}
.download_footer_warning, .download_footer_credit{
  color: rgb(239,68,29);
}
.download_footer_credit{
  margin-top: 5vh;
}
/*
*/
.card{
  background-color: rgba(0,0,0,0) !important;
  border: 0 !important;
}
.card_img{
  position: relative;
  left: 6px;
}
.card_text_zone{
  padding:0;
  margin:0;
}
.card-title, .card_text, .card-header{
  font-family: "HappyEnding-Regular", "Somma-Light","Trebuchet MS", Times, serif;
  font-weight: bold;
}
.card-header{
  font-size: 16px;
  background-color: rgba(255,255,255,.6);
  max-width: 400px;
}
.card-title, .card_text{
  color: rgb(120, 63, 129);
  margin:0;
}
.card-title{
  font-size: 16px;
}
.card_activity{
  font-size: 8px;
  color: gray;
}

.information {
  position: relative;
  height: calc(99vh + 10px);
  border: 2px solid rgba(120, 63, 129, 0.5);
  background-color: rgba(236, 231, 250, 0.7);
  top: 180px;
}
.information_header_container{
  width: 100%;
}

.information_header_gap{
  position: relative;
  height: 60px;
  width: 100%;
}

.information_text_container_noscroll, .information_text_container_base, .information_text_container{
  position: relative;
  background-color: rgba(236, 231, 250, 0.5);
  border: 1px solid rgb(110, 50, 120);
  padding: 20px 20px 20px 20px;
}

.information_text_container_about, .information_text_container_contact{
  background-color: #e8d1eb;
}

.information_text_container{
    width: 98%;
    margin: 0 0 80px 0;
}
.information_text_container_support, .information_text_container_account, .information_text_container_purchase_hist{
  min-height: 500px;
}

.information_text_container_support{
  max-height: 720px;
}
.information_text_container_contact{
  width: 100%;
  height: calc(60vh);
}
.information_text_container_account{
  min-width: 260px;
}
.information_container_account, .information_text_container_ourprice, .information_text_container_cart{
  width: 100%;
}
.information_text_scroll{
  overflow: scroll;
}

.information_wave_container{
  position: relative;
}

.information_download {
  position: relative;
  border: 2px solid rgba(120, 63, 129, 0.5);
  background-color: rgba(236, 231, 250, 0.8);
  left: 10%;
  width: 80%;
  z-index: 5;
}

.user_account, .user_account_error, .download_error{
  position: relative;
  z-index: 100;
  background-color: rgba(236, 231, 250, 0.5);
  margin: 10px;
}
.user_account_error, .download_error{
  font-size: 20px;
  font-weight: bold;
  color: rgb(239,68,29);
}

.user_account_label, .user_account_input{
  position: relative;
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  color: rgb(120, 63, 129);
  margin: 0 10px;
  display: inline;
}
.user_account_group{
  border: 2px solid rgb(110, 50, 120);
  margin: 20px 0px 0px 10px;
  padding: 10px;
}
.user_account_item{
  display: inline-block;
  min-width: 100px;
}
.user_account_item_email{
  display: inline-block;
}
.user_account_item_group{
  display: inline-block;
  margin: 10px;
}

.inf_LinkContainer{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  font-weight: 200;
  background-color: rgb(229, 211, 234);
  border: 1px solid rgb(110, 50, 120);
  border-radius: 0px;
  z-index: 1;
  position:relative;
  color: rgb(120, 63, 129);
}

.inf_LinkContainer.active{
  border: 2px solid rgb(110, 50, 120);
}

.btn-light:not(:disabled):not(.disabled).active {
  background-color: rgb(110, 50, 120);
  color: white !important;
}

.information_text_header, .information_title, .information_text, .text{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  color: rgb(120, 63, 129);
}
.information_text_header, .information_title, .information_text{
  margin-left: 20px;
  margin-right: 20px;
}
.information_text_header{
  position: relative;
  font-family: "HappyEnding-Regular","Somma-Light","Trebuchet MS", Times, serif;
  font-weight: bold;
  width: 98%;
}
.information_title{
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
.information_important{
  font-weight: bold;
  display: inline;
  color: rgb(120, 63, 129);
}
.information_text{
  font-weight: 300;
}
.information_text ul{
  margin-bottom: 0;
}
.information_reduction{
  text-decoration: line-through;
}
.information_center{
  text-align: center;
}
.buy-now{
  padding: 20px;
  font-size: larger;
  font-weight: bold;
}

.selectForm{
}
.selectFormPrice, .selectedPaymentModus{
  width: 100%;
  text-align: left;
  align-items: left;
}

/* Search */
.search{
  padding: 0;
}
/*
top: 25px;
*/
.search_col{
  z-index: 5;
}
.login_group{
  margin: 0 20px 0 20px;
}
/*
border: 1px solid red;
background-color: rgb(232, 209, 235) !important;
*/

.shoppingcart_button{
  background-color: white !important;
  margin: 0 3px 0 3px;
}
.header_button{
}
.search_panel, .search_facet_options, .header_button{
  white-space: nowrap;
}
.search_facet, .search_option, .search_option_label,.search_facet_change_on, .search_facet_change_off,.form-check-label-plus,.form-check-label-minus{
  cursor: grab;
}
.search_panel_gap{
  position: relative;
  height: 220px;
}
.search_panel_facet {
  padding: 0;
}
.search_panel_container{
  position: absolute;
  top: 0px;
  left:0px;
  z-index: 11;
  background-color: rgba(236, 231, 250, 0.7);
  border: 1px solid rgb(110, 50, 120);
  min-height: 100%;
  padding: 0 10px 0 10px;
}
.search_panel{
  position: relative;
  height:100%;
}
.search_option{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  color: rgb(120, 63, 129);
  padding-left: 0rem;
}
.search_facet{
  font-family: "Somma-Light","Trebuchet MS", Times, serif;
  color: rgb(120, 63, 129);
  font-weight: bold;
}
.search_facet_options{
}
.search_facet_header_hidden{
  min-height: 70px;
}
.search_facet_header{
}
.search_facet_options_hidden{
  display: none;
}

.search_facet_change_on, .search_facet_change_off{
  width: 15px;
  height: 15px;
  margin: 0 3px 0 3px;
}

.search_option_input{
  display: none;
}

.search_option_label{
  position: relative;
  margin: 0 5px 0 5px;
}

/* cart style */
table.cart {
  table-layout: auto;
}

.information_text_container_purchase_hist{

}
.purchase_seq{
  min-width: 80px;
}
.purchase_date{
  min-width: 110px;
}
.purchase_price{
  min-width: 70px;
}
.purchase_status{
  min-width: 50px;
}
.mystuff_seq {
  width: 180px;
}
.cart_seq {
  width: 80px;
}
.cart_price, .cart_total_price {
  width: 50px;
  white-space: nowrap;
  display: none;
}
.cart_img {
  width: 400px;
}
.cart_name {
}
.cart_text{
  vertical-align: middle !important;
}
.cart_text_desc{
}
.cart_button_remove{
  padding: 10px;
}
/* documentation styles */

.style_normal{

}
.style_space{
  margin-bottom: 1rem;
}

.style_red_bold, .style_red, .style_text_warning{
  color: rgb(239,68,29);
}

.style_red_bold, .style_text_warning{
  font-weight: bold
}
.style_red{
}

.style_text_bigger, .style_text_bigger_thin, .style_text_bigger_bold{
}

.style_text_bigger_thin{
  font-weight: 300;
}

.style_text_bigger_bold_warning{
  font-weight: bold;
}
.style_text_bigger_bold{
  font-weight: bold;
}

#shoppingCartNbItems{
  position: relative;
  float: right;
  height: 0;
  color: #6e3278;
}

.examples_presentation{
  position: relative;
  z-index: 10;
  padding-top: 100px;
}
.example_img{
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, .5);
}

.examples_header{
  position: relative;
  z-index: 10;
  margin : 10px 0 10px 0;
}

.exemple_col{
  position: relative;
  min-width: 160px;
  padding: 5px;
}

.example_block{
  display: inline;
}
