
//@use 'variables';
@import 'variables';

@import "~bootstrap/scss/bootstrap";

// https://sass-lang.com/guide

/*
%message-shared {
  border: 1px solid #ccc;
  padding: 10px;
  color: #333;
}

.message {
  @extend %message-shared;
}
*/

.login_LinkContainer {
  border-radius: 0px;
  color: white;
  background-color: $violet-dark-color;
  border: 2px solid $violet-dark-color;
}

//.carousel .control-arrow,
.carousel .dot{
  background: $violet-dark-color !important;
}
